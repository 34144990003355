import { FieldStructureTypes, JsonSchemaType } from '../enums/fields-structure.enums';
import {
  JsonSchemaBase,
  JsonSchemaEnum,
  JsonSchemaArray,
  JsonSchemaNumberArray,
} from '../interfaces/fields-structure.interfaces';

export function convertFieldStructureTypeToJsonSchemaType(type: FieldStructureTypes): JsonSchemaType {
  let convertedType;
  switch (type) {
    case FieldStructureTypes.ARRAY:
    case FieldStructureTypes.NUMBER_ARRAY:
    case FieldStructureTypes.STRING_ARRAY:
      convertedType = JsonSchemaType.ARRAY;
      break;
    case FieldStructureTypes.NUMBER:
      convertedType = JsonSchemaType.NUMBER;
      break;
    case FieldStructureTypes.BOOLEAN:
      convertedType = JsonSchemaType.BOOLEAN;
      break;
    case FieldStructureTypes.OBJECT:
      convertedType = JsonSchemaType.OBJECT;
      break;
    case FieldStructureTypes.STRING:
    case FieldStructureTypes.ENUM:
    default:
      convertedType = JsonSchemaType.STRING;
      break;
  }

  return convertedType;
}

export function identifyJsonNodeType(jsonNode: JsonSchemaBase): FieldStructureTypes {
  if (!jsonNode?.type) {
    return FieldStructureTypes.STRING;
  }

  if (jsonNode.type === JsonSchemaType.NUMBER) {
    return FieldStructureTypes.NUMBER;
  }
  if (jsonNode.type === JsonSchemaType.BOOLEAN) {
    return FieldStructureTypes.BOOLEAN;
  }
  if (jsonNode.type === JsonSchemaType.OBJECT) {
    return FieldStructureTypes.OBJECT;
  }
  if (jsonNode.type === JsonSchemaType.STRING) {
    return (jsonNode as JsonSchemaEnum).enum?.length ? FieldStructureTypes.ENUM : FieldStructureTypes.STRING;
  }
  if (jsonNode.type === JsonSchemaType.ARRAY) {
    return (jsonNode as JsonSchemaArray).items.enum?.length
      ? FieldStructureTypes.ARRAY
      : (jsonNode as JsonSchemaNumberArray).items.type === JsonSchemaType.NUMBER
      ? FieldStructureTypes.NUMBER_ARRAY
      : FieldStructureTypes.STRING_ARRAY;
  }

  return FieldStructureTypes.STRING;
}
