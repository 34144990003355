import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { operatorsContext, usersClientContext as clientContext } from '@portal/api-endpoints';
import {
  cleanEmptyValues,
  convertDatesInRequest,
  convertToOffsetDates,
  DEFAULT_PAGE_NUM,
  getEnumsTranslation,
  getHttpParams,
  replaceId,
} from '@portal/core';
import { ActionResultAgentStatistic } from '@portal/models/actionResultAgentStatistic';
import { ActionResultBoolean } from '@portal/models/actionResultBoolean';
import { ActionResultBuyerOrdersInfoShared } from '@portal/models/actionResultBuyerOrdersInfoShared';
import { ActionResultBuyerStatisticsShared } from '@portal/models/actionResultBuyerStatisticsShared';
import { ActionResultCommonAgentShared } from '@portal/models/actionResultCommonAgentShared';
import { ActionResultCommonBuyerShared } from '@portal/models/actionResultCommonBuyerShared';
import { ActionResultListAgentHistoryEvent } from '@portal/models/actionResultListAgentHistoryEvent';
import { ActionResultLong } from '@portal/models/actionResultLong';
import { ActionResultPageUserPaginationShared } from '@portal/models/actionResultPageUserPaginationShared';
import { ActionResultSellerStatisticsShared } from '@portal/models/actionResultSellerStatisticsShared';
import { ActionResultUser } from '@portal/models/actionResultUser';
import { AgentHistoryEvent } from '@portal/models/agentHistoryEvent';
import { AgentStatistic } from '@portal/models/agentStatistic';
import { BuyerOrdersInfoShared } from '@portal/models/buyerOrdersInfoShared';
import { BuyerStatisticsShared } from '@portal/models/buyerStatisticsShared';
import { CommonAgentCreateRequest } from '@portal/models/commonAgentCreateRequest';
import { CommonAgentShared } from '@portal/models/commonAgentShared';
import { CommonBuyerCreateRequest } from '@portal/models/commonBuyerCreateRequest';
import { CommonBuyerShared } from '@portal/models/commonBuyerShared';
import { CommonSellerCreateRequest } from '@portal/models/commonSellerCreateRequest';
import { DateRange } from '@portal/models/dateRange';
import { PageUserPaginationShared } from '@portal/models/pageUserPaginationShared';
import { SellerStatisticsShared } from '@portal/models/sellerStatisticsShared';
import { User } from '@portal/models/user';
import { UserCreateRequest } from '@portal/models/userCreateRequest';
import { UserFullUpdateRequest } from '@portal/models/userFullUpdateRequest';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { generateLinkAndDownload } from '../download/download.service';
import { LAST_OPERATIONS_NUMBER } from './users.constants';
import { AgentRolesUpdateWithId } from './users.interfaces';
import { UsersFiltersQuery, UsersQueryParams } from './users.service.interfaces';

@Injectable({
  providedIn: 'root',
})
export class UsersService implements TableUsersService {
  getItems = new Proxy(this.getUsers, {});

  constructor(private readonly translation: TranslateService, private readonly http: HttpClient) {}

  getUsers(params: UsersQueryParams = {}): Observable<PageUserPaginationShared> {
    return this.http
      .get<ActionResultPageUserPaginationShared>(clientContext.getUsers, { params: getHttpParams(params) })
      .pipe(map(r => r.value));
  }

  getBulkUsers(params: Pick<UsersQueryParams, 'ids' | 'page.size'>): Observable<PageUserPaginationShared> {
    return this.http
      .get<ActionResultPageUserPaginationShared>(clientContext.getUsers, {
        params: getHttpParams({ ...params, 'page.num': DEFAULT_PAGE_NUM }),
      })
      .pipe(map(r => r.value));
  }

  createUser = (data: UserCreateRequest): Observable<ActionResultLong> =>
    this.http.post<ActionResultLong>(clientContext.createUser, cleanEmptyValues(data));

  getUser = (userId: number): Observable<User> =>
    this.http
      .get<ActionResultUser>(replaceId(clientContext.getUser, userId), {
        params: getHttpParams({ includeAdditionalFields: true }),
      })
      .pipe(map(r => r.value));

  updateUser = (userId: number, data: UserFullUpdateRequest): Observable<ActionResultBoolean> =>
    this.http.put<ActionResultBoolean>(
      replaceId(clientContext.updateUser, userId),
      convertDatesInRequest(cleanEmptyValues(data), ['birthday']),
    );

  /** common buyer endpoints */
  createCommonBuyer = (data: CommonBuyerCreateRequest): Observable<ActionResultLong> =>
    this.http.post<ActionResultLong>(clientContext.commonBuyer.createCommonBuyer, data);

  getCommonBuyer = (buyerId: number): Observable<CommonBuyerShared> =>
    this.http
      .get<ActionResultCommonBuyerShared>(replaceId(clientContext.commonBuyer.getCommonBuyer, buyerId), {
        params: getHttpParams({ includeDeliveryAddresses: true }),
      })
      .pipe(map(r => r.value));

  getBuyerOrdersInfo = (userId: number): Observable<BuyerOrdersInfoShared> =>
    this.http
      .get<ActionResultBuyerOrdersInfoShared>(replaceId(clientContext.commonBuyer.getBuyerOrdersInfo, userId))
      .pipe(map(r => r.value));

  loadBuyerStatistics(userId: number, range: DateRange): Observable<BuyerStatisticsShared> {
    const dates = this.convertRangeToOffset(range);

    return this.http
      .get<ActionResultBuyerStatisticsShared>(replaceId(clientContext.commonBuyer.orderStatistics, userId), {
        params: getHttpParams({
          ['period.start']: dates.start,
          ['period.end']: dates.end,
        }),
      })
      .pipe(map((res: ActionResultBuyerStatisticsShared) => res.value));
  }
  /* common buyer endpoints **/

  /** common seller endpoints */
  createCommonSeller = (data: CommonSellerCreateRequest): Observable<ActionResultLong> =>
    this.http.post<ActionResultLong>(clientContext.commonSeller.createCommonSeller, data);

  getSellerStatistics = (id: number): Observable<SellerStatisticsShared> =>
    this.http
      .get<ActionResultSellerStatisticsShared>(replaceId(clientContext.commonSeller.getSellerStatistics, id))
      .pipe(map(r => r.value));
  /* commone seller endpoints **/

  /** common agent endpoints */
  createCommonAgent = (data: CommonAgentCreateRequest): Observable<ActionResultLong> =>
    this.http.post<ActionResultLong>(clientContext.commonAgent.createCommonAgent, cleanEmptyValues(data));

  getCommonAgent = (agentId: number): Observable<CommonAgentShared> =>
    this.http
      .get<ActionResultCommonAgentShared>(replaceId(clientContext.commonAgent.getCommonAgent, agentId))
      .pipe(map(r => r.value));

  updateCommonAgent = (agentId: number, data: CommonAgentCreateRequest): Observable<ActionResultBoolean> =>
    this.http.put<ActionResultBoolean>(
      replaceId(clientContext.commonAgent.updateCommonAgent, agentId),
      cleanEmptyValues(data),
    );

  getCommonAgentStatistic = (id: number): Observable<AgentStatistic> =>
    this.http
      .get<ActionResultAgentStatistic>(replaceId(clientContext.commonAgent.getCommonAgentStatistic, id))
      .pipe(map(r => r.value));

  getAgentLastOperations = (id: number): Observable<Array<AgentHistoryEvent>> =>
    this.http
      .get<ActionResultListAgentHistoryEvent>(replaceId(clientContext.commonAgent.getAgentLastOperations, id), {
        params: getHttpParams({ count: LAST_OPERATIONS_NUMBER.toString() }),
      })
      .pipe(map(r => r.value));

  updateRoles(data: AgentRolesUpdateWithId) {
    return this.http.put(replaceId(clientContext.updateRoles, data.id), data);
  }
  /* common agent endpoints **/

  createOperatorFromUser(userId: number): Observable<ActionResultLong> {
    return this.http.post<ActionResultLong>(operatorsContext.operators, { userId });
  }

  createCourierFromUser(userId: number): Observable<ActionResultLong> {
    return this.http.post<ActionResultLong>(clientContext.courier.createCourier, { userId });
  }

  /** translations */

  getClientTypesTranslations = () =>
    getEnumsTranslation(this.translation.get('components.users.recentApplicationsTable.clientTypes'));

  getOperationTypesTranslations = () =>
    getEnumsTranslation(this.translation.get('components.users.recentApplicationsTable.operationTypes'));

  getDocumentTypeTranslation = () => getEnumsTranslation(this.translation.get('components.users.documents.types'));

  getProfileTypesTranslation = () => getEnumsTranslation(this.translation.get('orders.buyerType'));

  getStatusesTranslation = () => getEnumsTranslation(this.translation.get('components.users.table.statuses'));

  // TODO better content-disposition, replace with downloadService.downloadFile method inside a component
  async downloadFile(href: string, name: string) {
    const response = await fetch(href);
    const blob = await response.blob();

    generateLinkAndDownload(blob, name);
  }

  private convertRangeToOffset(range: DateRange): { start: Date; end: Date } {
    const convertedRange = convertToOffsetDates<DateRange, 'start' | 'end'>(range, ['start', 'end']);

    return { start: convertedRange.start as Date, end: convertedRange.end as Date };
  }
}

// TODO: move TableService to services (if no more circular dependencies be involved)
//  or figure out another solution;
interface TableUsersService {
  getItems(filters?: UsersFiltersQuery & UsersPaginatorQuery, anyArgs?: object): Observable<PageUserPaginationShared>;
}

interface UsersPaginatorQuery {
  'page.size': number;
  'page.num': number;
}
