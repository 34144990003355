import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[portalSetBackgroundImage]',
})
export class SetBackgroundImageDirective implements OnInit {
  @Input('portalSetBackgroundImage') path: string;

  constructor(private readonly el: ElementRef) {}

  ngOnInit(): void {
    if (!!this.path) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.el.nativeElement.style.backgroundImage = this.urlPath;
    }
  }

  get urlPath(): string {
    if (this.path.includes('url')) {
      return this.path;
    }

    return 'url(' + this.path + ')';
  }
}
