import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { pageBuilderContext } from '@portal/api-endpoints';
import { AGENT_TYPE, PermissionsService } from '@portal/auth';
import { getHttpParams } from '@portal/core';
import { UserPaginationShared } from '@portal/models/userPaginationShared';
import equal from 'fast-deep-equal/es6';
import { Observable } from 'rxjs/internal/Observable';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { map } from 'rxjs/internal/operators/map';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import ProfileTypesEnum = UserPaginationShared.ProfileTypesEnum;

interface GuideLink {
  guideLink: string;
  key: string;
}

@Injectable()
export class HintsService {
  keys$: Observable<Map<string, string>>;
  links$: Observable<Map<string, string>>;
  private readonly permissionsService: PermissionsService

  constructor(
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    @Inject(AGENT_TYPE) private readonly agentType: ProfileTypesEnum,

  ) {
    this.permissionsService = inject(PermissionsService);
    this.links$ = this.loadLinks();
    this.keys$ = this.loadKeys();
  }

  tryToGetLink(translation: string): Observable<string | null> {
    return combineLatest([this.keys$, this.links$]).pipe(
      map(([keys, links]: [Map<string, string>, Map<string, string>]) => {
        const key = keys.get(translation);
        if (!key) {
          return null;
        }
        const link = links.get(key);

        return link ?? null;
      }),
      shareReplay(1),
    );
  }

  protected loadLinks(): Observable<Map<string, string>> {
    return (
      this.permissionsService.pagesPermissions.view
        ? this.http.get<{ value: GuideLink[] }>(pageBuilderContext.hints, {
            params: getHttpParams({ portal: this.agentType.toLowerCase() }),
          })
        : of({ value: [] })
    ).pipe(
      catchError(() => of(null)),
      map((res: { value: GuideLink[] }) =>
        res?.value.reduce((acc: Map<string, string>, link) => acc.set(link.key, link.guideLink), new Map()),
      ),
      shareReplay(1),
    );
  }

  private loadKeys() {
    return this.translateService.getTranslation(this.translateService.currentLang).pipe(
      distinctUntilChanged(equal),
      map((res: object) =>
        Object.entries(this.flatten(res)).reduce(
          (acc: Map<string, string>, [key, value]) => acc.set(value, key),
          new Map(),
        ),
      ),
      shareReplay(1),
    );
  }

  private flatten(obj: string | object, path = ''): Record<string, string> {
    if (!(obj instanceof Object)) {
      return { [path.replace(/\.$/g, '')]: obj };
    }

    return Object.keys(obj).reduce(
      (output, key: string) =>
        obj instanceof Array
          ? { ...output, ...this.flatten(obj[+key], path + '[' + key + '].') }
          : { ...output, ...this.flatten((obj as Record<string, string>)[key], path + key + '.') },
      {},
    );
  }
}
