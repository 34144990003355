import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[portalClickOutside]',
})
export class ClickOutsideDirective {
  // skipFirstClick in order to not catch the first click which component initialization triggered
  @Input() skipFirstClick = true;
  @Output() readonly portalClickOutside = new EventEmitter();

  clickCount = 0;

  constructor(private readonly element: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    this.clickCount++;

    if (!this.skipFirstClick || this.clickCount !== 1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      const clickedInside = this.element.nativeElement.contains(targetElement);

      if (!clickedInside) {
        this.portalClickOutside.emit(null);
      }
    }
  }
}
