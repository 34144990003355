import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { ActionNotification, ActiveNotification, NotificationType } from './notifications.interface';
import { DefaultSnackBarComponent } from './default-snack-bar';
import { DefaultNotification } from './notifications.constants';
import { Observable } from 'rxjs/internal/Observable';
import { Responses } from '../../interfaces';
import { catchError, switchMap } from 'rxjs/operators';
import { NEVER } from 'rxjs/internal/observable/never';
import { stringifyError } from '../../shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Method {@link handleResponse} should be used as a rxjs operator if it is necessary to implement
 * custom error handling of the request. Action of onError parameter is called twice
 * (in switchMap and in catchError operators)
 * because native https errors as well as custom server errors should be handled.
 * In case server sends usual https errors instead of response with status 200 and errorCode,
 * this method should be removed and custom error handling
 * should be implemented in catchError operator in the request itself.
 */

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private readonly snackBar: MatSnackBar) {}

  showSuccessMessage(notification: ActiveNotification = {}) {
    this.openSnackBar({ ...DefaultNotification, ...notification, type: NotificationType.Success });
  }

  showErrorMessage(notification: ActiveNotification = {}) {
    this.openSnackBar({
      ...DefaultNotification,
      ...notification,
      type: NotificationType.Error,
      message: notification?.response ? stringifyError(notification?.response) : notification?.message || '',
    });
  }

  /**
   * @param onError contains action that should be implemented on error and message to show
   * @param onSuccess contains only message to show as all action
   * can be implemented inside the subscribe of an observable
   */
  handleResponse(onError: ActionNotification = {}, onSuccess: ActiveNotification = {}) {
    return (source: Observable<Responses>): Observable<Responses> =>
      source.pipe(
        switchMap((r: Responses) => {
          r.success
            ? this.showSuccessMessage({ message: onSuccess?.message })
            : this.showErrorMessage({ response: r, message: onError?.message });

          if (!r.success && onError.action) {
            onError.action();
          }

          return r.success ? of(r) : NEVER;
        }),
        catchError(() => {
          if (onError?.action) {
            onError.action();
          }
          this.showErrorMessage({ message: onError?.message || '' });

          return NEVER;
        }),
      );
  }

  private openSnackBar({ message, showOnlyMessage, type, duration }: ActiveNotification) {
    const text = typeof message === 'string' ? of(message) : message;

    const data: ActiveNotification = {
      type,
      message: text,
      showOnlyMessage,
    };

    this.snackBar.openFromComponent(DefaultSnackBarComponent, {
      duration,
      panelClass: ['transparent-colored-background'],
      data,
    });
  }
}
