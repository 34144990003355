import { NgModule } from '@angular/core';
import { RequestLoaderComponent } from './request-loader.component';
import { CoreModule } from '../../core.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [RequestLoaderComponent],
  imports: [CoreModule, MatProgressBarModule],
  exports: [RequestLoaderComponent],
})
export class RequestLoaderModule {}
