/**
 * User service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { FullName } from './fullName';
import { JsonNode } from './jsonNode';
import { PhoneNumber } from './phoneNumber';
import { SimpleImage } from './simpleImage';

export interface UserPaginationShared { 
    id?: number;
    fullName?: FullName;
    email?: string;
    phoneNumber?: PhoneNumber;
    avatar?: SimpleImage;
    verified?: boolean;
    blocked?: boolean;
    profileTypes?: Array<UserPaginationShared.ProfileTypesEnum>;
    createdAt?: Date;
    updatedAt?: Date;
    additionalFields?: { [key: string]: JsonNode; };
    sex?: UserPaginationShared.SexEnum;
    birthday?: string;
    registrationAddress?: Address;
}
export namespace UserPaginationShared {
    export type ProfileTypesEnum = 'COMMON_BUYER' | 'COMMON_SELLER' | 'LEGAL_BUYER' | 'LEGAL_SELLER' | 'OPERATOR' | 'COMMON_AGENT' | 'LEGAL_AGENT' | 'UNKNOWN' | 'MODERATOR' | 'TECHNICAL_USER' | 'COURIER';
    export const ProfileTypesEnum = {
        COMMONBUYER: 'COMMON_BUYER' as ProfileTypesEnum,
        COMMONSELLER: 'COMMON_SELLER' as ProfileTypesEnum,
        LEGALBUYER: 'LEGAL_BUYER' as ProfileTypesEnum,
        LEGALSELLER: 'LEGAL_SELLER' as ProfileTypesEnum,
        OPERATOR: 'OPERATOR' as ProfileTypesEnum,
        COMMONAGENT: 'COMMON_AGENT' as ProfileTypesEnum,
        LEGALAGENT: 'LEGAL_AGENT' as ProfileTypesEnum,
        UNKNOWN: 'UNKNOWN' as ProfileTypesEnum,
        MODERATOR: 'MODERATOR' as ProfileTypesEnum,
        TECHNICALUSER: 'TECHNICAL_USER' as ProfileTypesEnum,
        COURIER: 'COURIER' as ProfileTypesEnum
    };
    export type SexEnum = 'UNKNOWN' | 'MALE' | 'FEMALE';
    export const SexEnum = {
        UNKNOWN: 'UNKNOWN' as SexEnum,
        MALE: 'MALE' as SexEnum,
        FEMALE: 'FEMALE' as SexEnum
    };
}