import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  pure: true,
})
export class SortPipe implements PipeTransform {
  transform<T extends P & { [key: string]: P }, P = object>(items: T[], field: string, alphabetically = false): T[] {
    if (!items || !field) {
      return items;
    }

    if (!alphabetically) {
      return items.sort(item => (item[field] ? -1 : 1));
    }

    return items.sort((a: Record<string, unknown>, b: Record<string, unknown>) => {
      const nameA = (a[field] as string)?.toLowerCase();
      const nameB = (b[field] as string)?.toLowerCase();

      return nameA < nameB ? -1 : 1;
    });
  }
}
