import { Pipe, PipeTransform } from '@angular/core';
import { MarketTemplateShared } from '@portal/models/marketTemplateShared';

@Pipe({
  name: 'portalTemplateCell',
  pure: true,
})
export class PortalTemplateCellPipe implements PipeTransform {
  transform(value?: Array<MarketTemplateShared>): string | null {
    if (!value) {
      return null;
    }

    return value.map(v => (v?.systemName ? `${v.systemName}(${v.deliveryMethod})` : '-')).join(', ');
  }
}
