import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CanLoad, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HidePageGuard implements CanLoad {
  constructor(private readonly router: Router) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    this.router.navigate(['/']);

    return false;
  }
}
