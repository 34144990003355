import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { serverSettingsClientContext } from '@portal/api-endpoints';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { BuilderInfoService } from '@portal/builder';
import { SettingsResponse } from './profile-settings.interface';

@Injectable({ providedIn: 'root' })
export class ProfileSettingsService {
  constructor(private readonly http: HttpClient, private readonly builderInfoService: BuilderInfoService) {}

  getSettings(): Observable<{ value: SettingsResponse; success: boolean }> {
    return this.http.get<{ value: SettingsResponse; success: boolean }>(serverSettingsClientContext.getCurrent).pipe(
      map(v => {
        if (!v.success || !v.value) {
          return {
            value: null,
            success: true,
          };
        }

        return v;
      }),
      catchError(() => of({ value: null, success: true })),
      tap(v => {
        this.builderInfoService.updateSettings(v.value);
      }),
    );
  }
}
