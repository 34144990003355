/**
 * Item service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CharacteristicRequest } from './characteristicRequest';
import { Sku } from './sku';

export interface Item { 
    mpId?: number;
    sellerItemId?: string;
    brandId?: number;
    regionId?: number;
    characteristics?: CharacteristicRequest;
    name?: string;
    description?: string;
    baseImageUrl?: string;
    extraImageUrls?: Array<string>;
    tax?: number;
    productType?: Item.ProductTypeEnum;
    status?: Item.StatusEnum;
    sku?: Array<Sku>;
}
export namespace Item {
    export type ProductTypeEnum = 'PHYSICAL' | 'DIGITAL' | 'FINANCIAL_ITEM' | 'TENDER' | 'DIGITAL_CALENDAR';
    export const ProductTypeEnum = {
        PHYSICAL: 'PHYSICAL' as ProductTypeEnum,
        DIGITAL: 'DIGITAL' as ProductTypeEnum,
        FINANCIALITEM: 'FINANCIAL_ITEM' as ProductTypeEnum,
        TENDER: 'TENDER' as ProductTypeEnum,
        DIGITALCALENDAR: 'DIGITAL_CALENDAR' as ProductTypeEnum
    };
    export type StatusEnum = 'HIDDEN' | 'PUBLISHED' | 'DELETED';
    export const StatusEnum = {
        HIDDEN: 'HIDDEN' as StatusEnum,
        PUBLISHED: 'PUBLISHED' as StatusEnum,
        DELETED: 'DELETED' as StatusEnum
    };
}