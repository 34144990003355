import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import _get from 'lodash/get';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(
    value: any,
    type: SafePipeTypes = SafePipeTypes.ResourceUrl,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    const actionMap = {
      [SafePipeTypes.Style]: () => this.sanitizer.bypassSecurityTrustStyle(value),
      [SafePipeTypes.Html]: () => this.sanitizer.bypassSecurityTrustHtml(value),
      [SafePipeTypes.Script]: () => this.sanitizer.bypassSecurityTrustScript(value),
      [SafePipeTypes.Url]: () => this.sanitizer.bypassSecurityTrustUrl(value),
      [SafePipeTypes.ResourceUrl]: () => this.sanitizer.bypassSecurityTrustResourceUrl(value),
    };

    if (actionMap[type]) {
      return actionMap[type]();
    }

    throw new Error(`Invalid safe type specified: ${type}`);
  }
}

export enum SafePipeTypes {
  Html,
  Style,
  Script,
  Url,
  ResourceUrl,
}

export function getUrlFromSafeUrl(safeUrl: SafeResourceUrl): string | SafeResourceUrl {
  return _get(safeUrl, 'changingThisBreaksApplicationSecurity', safeUrl);
}
