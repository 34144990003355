import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RequestLoaderService } from './request-loader.service';
import { map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { merge } from 'rxjs/internal/observable/merge';
import { timer } from 'rxjs/internal/observable/timer';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
import { Times } from '../../enums';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'portal-request-loader',
  templateUrl: './request-loader.component.html',
  styleUrls: ['./request-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestLoaderComponent implements OnInit {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  progress$: Observable<number>;
  isScreenLocked$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly maxProgress = 100;

  constructor(private readonly preloaderService: RequestLoaderService, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.progress$ = this.preloaderService.loaderState.asObservable().pipe(
      switchMap(({ isLoading, lockScreen }) => {
        if (!isLoading) {
          return this.complete$();
        }

        this.isLoading$.next(true);
        this.isScreenLocked$.next(lockScreen);

        const endProgress = 70;
        this.cdr.detectChanges();

        return timer(0, this.maxProgress).pipe(
          map(value => (value + 1) * Times.Ten),
          takeWhile(percents => percents <= endProgress),
        );
      }),
    );
  }

  complete$(): Observable<number> {
    const progressEnd$ = of(this.maxProgress).pipe(
      tap(() => {
        this.isScreenLocked$.next(false);
        this.isLoading$.next(false);
      }),
    );

    return merge(progressEnd$);
  }
}
