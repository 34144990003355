import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BuilderInfoService } from '@portal/builder';
import { InvoiceTemplateResponse } from '@portal/models/invoiceTemplateResponse';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import LangEnum = InvoiceTemplateResponse.LangEnum;

@Injectable({
  providedIn: 'root',
})
export class InternalizationService {
  private readonly key = 'language';

  constructor(
    private readonly translation: TranslateService,
    private readonly builderInfoService: BuilderInfoService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.initDefault();
    this.initQueryParamsListener();
  }

  getLanguage(): string | undefined {
    return localStorage.getItem(this.key);
  }

  setLanguage(lang: string) {
    if (lang && lang === this.getLanguage()) {
      return;
    }
    localStorage.setItem(this.key, lang);
    this.translation.use(lang);
    location.reload(); // re init locales
  }

  initDefault() {
    const lang = this.getLanguage() || this.builderInfoService.lang.toLowerCase();
    this.translation.setDefaultLang(lang);
    this.translation.use(lang);
  }

  initQueryParamsListener(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((params: Params) => params?.lang),
        distinctUntilChanged(),
        filter(
          (lang: string) =>
            !!lang &&
            this.builderInfoService.availableLangs.some(
              availableLang => availableLang.toLowerCase() === lang.toLowerCase(),
            ),
        ),
      )
      .subscribe((lang: LangEnum) => {
        this.setLanguage(lang.toLowerCase());
      });
  }
}
