import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { SerializedRouterState } from './router.serializer.interface';

export class RouterState implements RouterReducerState<SerializedRouterState> {
  activatedRoute = new SerializedRouterState();
  state = new SerializedRouterState();
  navigationId: number = null;
}

export namespace RouterSelectors {
  const getRouter = createFeatureSelector<RouterState>('router');

  export const getActivatedRouterState = createSelector(
    getRouter,
    router => router.state || new SerializedRouterState(),
  );
  export const getActivatedRouterQueryParams = createSelector(getActivatedRouterState, state => state.queryParams);
  export const getActivatedRouterParams = createSelector(getActivatedRouterState, state => state.params);
}
