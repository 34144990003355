import _isObjectLike from 'lodash/isObjectLike';
import _isArray from 'lodash/isArray';
import _isDate from 'lodash/isDate';

export function cleanEmptyValues<T extends object | object[]>(obj: T): T {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (_isObjectLike(value) && !isObjectEmpty(value)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (acc as any)[key] = cleanEmptyValues(value);
      } else if (isNotEmptyValue(value)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (acc as any)[key] = value;
      }

      return acc;
    },
    _isArray(obj) ? [] : {},
  ) as T;
}

export function isNotEmptyValue(value: unknown): boolean {
  return (
    value === 0 ||
    value === false ||
    _isDate(value) ||
    (_isObjectLike(value) && !isObjectEmpty(value as object)) ||
    (!_isObjectLike(value) && !!value)
  );
}

export function isObjectEmpty(obj: object): boolean {
  return obj === undefined || obj === null || !Object.entries(obj).filter(([_, v]) => isNotEmptyValue(v)).length;
}
