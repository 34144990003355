import { DictionaryValueShared } from '@portal/models/dictionaryValueShared';
import { District } from '@portal/models/district';

export interface DictionaryQueryParams {
  name?: string;
  names?: string[];
  statusList?: DictionaryValueStatus[];
}

export enum DictionaryNameEnum {
  REGIONS = 'REGIONS',
  COUNTRIES = 'COUNTRIES',
  CITIES = 'CITY',
  AREAS = 'AREA',
  DELIVERY_ZONES = 'DELIVERY_ZONES',
  SOURCING_DISTRICTS = 'SOURCING_DISTRICTS',
  CROPS = 'CROPS',
}

export enum DictionaryValueStatus {
  HIDDEN = 'HIDDEN',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export interface SourcingDistrictsDictionary extends Omit<DictionaryValueShared, 'value'> {
  value: District;
}
