import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuilderInfoService } from '@portal/builder';
import { getHttpParams, replaceId } from '@portal/core';
import { ActionResultItemFsDetailsShared } from '@portal/models/actionResultItemFsDetailsShared';
import { clientContext } from '../client.context';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private readonly http: HttpClient, private readonly builderInfoService: BuilderInfoService) {}

  getItemById(id: number) {
    const url = replaceId(clientContext.filter.itemById, id);

    return this.http.get<ActionResultItemFsDetailsShared>(url, { params: this.getParamsWithIdCurrency() });
  }

  private getParamsWithIdCurrency(id?: number) {
    const params = { currency: this.builderInfoService.currency, skipErrorHandling: true };

    return getHttpParams(id ? { ...params, ids: `${id}` } : params);
  }
}
