import { Directive, ElementRef, Input, OnInit, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[portalClickableChipLink]',
})
export class ClickableChipDirective implements OnInit, AfterViewInit {
  @Input() portalClickableChipLink: string;
  originalText?: HTMLSpanElement;

  constructor(private readonly element: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    this.originalText = this.element.nativeElement.querySelector('.chip-label');
  }

  ngAfterViewInit(): void {
    if (!this.originalText) {
      this.originalText = this.element.nativeElement.querySelector('.chip-label');
    }
    if (this.originalText?.innerText?.length) {
      this.wrapTextInLink();
    }
  }

  private wrapTextInLink(): void {
    const link = this.renderer.createElement('a');
    const text: HTMLSpanElement = this.renderer.createElement('span');
    text.innerHTML = this.originalText.innerHTML;
    this.renderer.setAttribute(link, 'href', this.portalClickableChipLink);
    this.renderer.setAttribute(link, 'target', '_blank');
    this.renderer.addClass(link, 'chip-label');
    this.renderer.appendChild(link, text);
    this.originalText.replaceWith(link);
  }
}
