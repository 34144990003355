import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { RequestLoaderService } from './request-loader.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class RequestLoaderInterceptor implements HttpInterceptor {
  constructor(private readonly loaderService: RequestLoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.params.get('skipLoaderLock')) {
      return next.handle(req);
    }

    const lockScreen = req.params.get('lockScreen');
    this.loaderService.show(!!lockScreen);

    return next.handle(req).pipe(
      finalize(() => {
        this.loaderService.hide();
      }),
    );
  }
}
