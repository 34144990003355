import { RunTimeConfig } from './run-time-config.interface';
import _defaultsDeep from 'lodash/defaultsDeep';
import _assign from 'lodash/assign';
import { builderEnvironment, Theme } from '@portal/builder';
import { ApiEndpoints } from '../environments/evironment.interface';

export async function getRuntimeConfig(): Promise<RunTimeConfig> {
  const runtimeConfig = await fetch('assets/run-time-config/runTimeConfig.json');

  return runtimeConfig.json() as Promise<RunTimeConfig>;
}

export function setRunTimeConfig(config: RunTimeConfig, endpoints: ApiEndpoints) {
  validateAndFixConfig(config);

  _assign(endpoints, _defaultsDeep(config.endpoints, endpoints));
  _assign(builderEnvironment, _defaultsDeep(config.builderEnvironment, builderEnvironment));
}

function validateAndFixConfig(config: RunTimeConfig): void {
  // validate theme
  if (!Object.values(Theme).includes(config.builderEnvironment.theme)) {
    config.builderEnvironment.theme = Theme.DEFAULT;
  }
}
