import { NgModule } from '@angular/core';
import { TextCutDirective } from './text-cut/text-cut.directive';
import { LetDirective } from './let.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { SetBackgroundImageDirective } from './add-background-image.directive';
import { CalculateFromFullHeightDirective } from './calc-from-full-height';
import { DefaultImageOnErrorDirective } from './default-image-on-error';
import { ClickableChipDirective } from './clickable-chip';

const directives = [
  TextCutDirective,
  LetDirective,
  ClickOutsideDirective,
  SetBackgroundImageDirective,
  CalculateFromFullHeightDirective,
  ClickableChipDirective,
  DefaultImageOnErrorDirective,
];

@NgModule({
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {}
