/**
 * CMS service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 *
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlockElementV2Shared } from "./blockElementV2Shared";
import { BlockTemplate } from "./blockTemplate";
import { BlockType } from "./blockType";
import { ElementTemplate } from "./elementTemplate";
import { SimpleImage } from "./simpleImage";
import { UrlValue } from "./urlValue";

export interface PageContentBlockV2Shared {
    id?: number;
    pageContentLineId?: number;
    parentId?: number;
    parentBlock?: boolean;
    staticBlock?: boolean;
    blockType?: BlockType;
    sortOrder?: number;
    title: string;
    metaUrl?: UrlValue;
    metaTitle?: string;
    metaImage?: SimpleImage;
    metaDescription?: string;
    staticFields?: Array<string>;
    template?: BlockTemplate;
    lgCol?: number;
    mdCol?: number;
    smCol?: number;
    xsCol?: number;
    additionalFields?: { [key: string]: any; };
    elementType?: PageContentBlockV2Shared.ElementTypeEnum;
    elementTemplate?: ElementTemplate;
    elements?: Array<BlockElementV2Shared>;
    rules?: { [key: string]: number; };
    temporary?: boolean;
    activeFrom?: Date;
    activeTo?: Date;
    regionIds?: Array<number>;
    children?: PageContentBlockV2Shared[];
}
export namespace PageContentBlockV2Shared {
    export type ElementTypeEnum = 'ARTICLE' | 'FINANCIAL_ITEM' | 'PARTNER' | 'PARTNER_PRODUCT' | 'ITEM' | 'WIDGET' | 'CATEGORY' | 'TAG' | 'CUSTOM' | 'ITEM_CATEGORY';
    export const ElementTypeEnum = {
        ARTICLE: 'ARTICLE' as ElementTypeEnum,
        FINANCIALITEM: 'FINANCIAL_ITEM' as ElementTypeEnum,
        PARTNER: 'PARTNER' as ElementTypeEnum,
        PARTNERPRODUCT: 'PARTNER_PRODUCT' as ElementTypeEnum,
        ITEM: 'ITEM' as ElementTypeEnum,
        WIDGET: 'WIDGET' as ElementTypeEnum,
        CATEGORY: 'CATEGORY' as ElementTypeEnum,
        TAG: 'TAG' as ElementTypeEnum,
        CUSTOM: 'CUSTOM' as ElementTypeEnum,
        ITEMCATEGORY: 'ITEM_CATEGORY' as ElementTypeEnum
    };
}
