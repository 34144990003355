import { Injectable } from '@angular/core';
import { ContentTypeEnum, getMultipartFormData, replaceId } from '@portal/core';
import { clientContext } from '../client.context';
import { ImageWrapperShared } from '@portal/models/imageWrapperShared';
import { ActionResultBoolean } from '@portal/models/actionResultBoolean';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class CompaniesSharedImagesService {
  constructor(private readonly http: HttpClient) {}

  upload(image: File, id: number, request?: number): Observable<ActionResultBoolean> {
    const payload = getMultipartFormData([
      { name: 'image', value: image },
      { name: 'request', value: { sortOrder: request } },
    ]);

    return this.http.post<ActionResultBoolean>(
      replaceId(clientContext.companies.uploadImage, id),
      payload,
      this.getMultipartHeaders,
    );
  }

  update(image: File, companyId: number, sortOrder: number, imageId: number): Observable<ActionResultBoolean> {
    const payload = getMultipartFormData([
      { name: 'image', value: image },
      { name: 'request', value: { sortOrder } },
    ]);

    return this.http.put(
      this.replaceIdAndImageId(clientContext.companies.updateImage, companyId, imageId),
      payload,
      this.getMultipartHeaders,
    );
  }

  delete(imageId: number, companyId: number): Observable<ActionResultBoolean> {
    return this.http.delete(this.replaceIdAndImageId(clientContext.companies.deleteImage, companyId, imageId));
  }

  changeMeta(image: ImageWrapperShared, order: number, itemId: number): Observable<ActionResultBoolean> {
    const payload = getMultipartFormData([
      { name: 'request', value: { order, altAttribute: image.altAttribute, metaTitle: image.metaTitle } },
    ]);

    return this.http.put(
      this.replaceIdAndImageId(clientContext.companies.updateImage, itemId, image.id),
      payload,
      this.getMultipartHeaders,
    );
  }

  get getMultipartHeaders() {
    return { headers: { 'Content-Type': ContentTypeEnum.MultipartForm } };
  }

  private replaceIdAndImageId(url: string, id: number, imageId: number) {
    return replaceId(url, id).replace('{imageId}', `${imageId}`);
  }
}
