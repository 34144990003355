import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dictionaryContext } from '@portal/api-endpoints';
import { BuilderInfoService } from '@portal/builder';
import { getHttpParams, replaceId } from '@portal/core';
import { ActionResultListAutoSuggestShared } from '@portal/models/actionResultListAutoSuggestShared';
import { ActionResultListString } from '@portal/models/actionResultListString';
import { ActionResultMapStringListDictionaryValueShared } from '@portal/models/actionResultMapStringListDictionaryValueShared';
import { AutoSuggestShared } from '@portal/models/autoSuggestShared';
import { CountryWrapper } from '@portal/models/countryWrapper';
import { DictionaryValueShared } from '@portal/models/dictionaryValueShared';
import { District } from '@portal/models/district';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { clientContext } from '../client.context';
import { DictionaryNameEnum, DictionaryQueryParams, SourcingDistrictsDictionary } from './dictionary.interface';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor(
    private readonly http: HttpClient,
    private readonly builderInfoService: BuilderInfoService,
    private readonly translateService: TranslateService,
  ) {}

  getDictionaryItems(params?: DictionaryQueryParams): Observable<Record<string, DictionaryValueShared[]>> {
    return this.http
      .get<ActionResultMapStringListDictionaryValueShared>(clientContext.dictionary, { params: getHttpParams(params) })
      .pipe(map(r => r.value));
  }

  getDistricts(): Observable<{ id: number; value: District }[]> {
    const params: DictionaryQueryParams = {
      names: [DictionaryNameEnum.SOURCING_DISTRICTS],
    };

    return this.getDictionaryItems(params).pipe(
      map(items =>
        items.SOURCING_DISTRICTS?.filter(item => !!item.value).map(item => ({
          id: item.id,
          value: JSON.parse(item.value) as unknown as District,
        })),
      ),
    );
  }

  loadCrops(): Observable<DictionaryValueShared[]> {
    return this.getDictionaryByName(DictionaryNameEnum.CROPS).pipe(
      map((crops: DictionaryValueShared[]) =>
        crops?.sort((a: DictionaryValueShared, b: DictionaryValueShared) => a.value?.localeCompare(b?.value)),
      ),
    );
  }

  getRegions(search = ''): Observable<AutoSuggestShared[]> {
    const COUNTRY_CODE_LENGTH = 2;
    const params = getHttpParams({
      nameStartsWith: search,
      country: (this.builderInfoService.settings?.country || 'Russia').substr(0, COUNTRY_CODE_LENGTH).toUpperCase(),
      lang: this.translateService.currentLang?.toUpperCase(),
    });

    return this.http
      .get<ActionResultListAutoSuggestShared>(dictionaryContext.regions, { params })
      .pipe(map(({ value }) => value || []));
  }

  getCountries(search = ''): Observable<CountryWrapper[]> {
    const params = getHttpParams({
      search,
      name: 'COUNTRIES',
      lang: this.translateService.currentLang?.toUpperCase(),
    });

    return this.http.get<{ value: DictionaryValueShared[] }>(dictionaryContext.dictionary, { params }).pipe(
      map(res =>
        res.value?.map(v => ({
          ...v,
          country: { ...JSON.parse(v.value || '{}') },
        })),
      ),
    );
  }

  getCities(search = '', parentId?: number): Observable<CountryWrapper[]> {
    const params = getHttpParams({
      search,
      name: 'CITIES',
      lang: this.translateService.currentLang?.toUpperCase(),
      ...(parentId ? { parentId } : {}),
    });

    return this.http.get<{ value: DictionaryValueShared[] }>(dictionaryContext.dictionary, { params }).pipe(
      map(res =>
        res.value?.map(v => ({
          ...v,
          country: { ...JSON.parse(v.value || '{}') },
        })),
      ),
    );
  }

  getDictionaryByName(name: DictionaryNameEnum, search = '', parentId?: number): Observable<DictionaryValueShared[]> {
    const params = getHttpParams({
      search,
      name,
      lang: this.translateService.currentLang?.toUpperCase(),
      ...(parentId ? { parentId } : {}),
    });

    return this.http
      .get<{ value: DictionaryValueShared[] }>(dictionaryContext.dictionary, { params })
      .pipe(map(res => res.value));
  }

  loadDictionaryItem<T>(id: number): Observable<DictionaryValueShared & { value: T }> {
    return this.http
      .get<{ value: DictionaryValueShared & { value: T } }>(replaceId(dictionaryContext.dictionaryItem, id))
      .pipe(map(res => ({ ...res, value: JSON.parse(res.value.value || '{}') })));
  }

  filterDistrictsByState(
    value?: string,
    districts?: SourcingDistrictsDictionary[],
    state?: string,
  ): SourcingDistrictsDictionary[] {
    return districts?.filter(
      option =>
        option.value.state.toLowerCase().includes(state?.toLowerCase() || '') &&
        option.value.district.toLowerCase().includes(value?.toLowerCase() || ''),
    );
  }

  getStatesFromDistricts(districts?: SourcingDistrictsDictionary[]): string[] {
    return Array.from(new Set(districts?.map(d => d.value.state)));
  }

  loadShippingTerms(): Observable<string[]> {
    return this.http.get<ActionResultListString>(dictionaryContext.shippingTerms).pipe(map(res => res.value));
  }

  loadAvailableCurrencies(): Observable<string[]> {
    return this.http.get<ActionResultListString>(dictionaryContext.availableCurrencies).pipe(map(res => res.value));
  }
}
