import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { PortalRouterStateSerializer } from './router.serializer';

@NgModule({
  imports: [
    StoreRouterConnectingModule.forRoot({
      serializer: PortalRouterStateSerializer,
    }),
    StoreModule.forFeature('router', routerReducer),
  ],
})
export class RouterStateModule {}
