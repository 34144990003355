import { PaginatorQuery } from '@portal/components';

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_HUGE_PAGE_SIZE = 10000;
export const DEFAULT_PAGE_NUM = 1;

export const DEFAULT_PAGINATOR_QUERY: PaginatorQuery = {
  'page.size': DEFAULT_PAGE_SIZE,
  'page.num': DEFAULT_PAGE_NUM,
};
