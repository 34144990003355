import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalAddressPipe } from './address/address.pipe';
import { HumanBooleanPipe } from './human-boolean.pipe';
import { PortalLangPipe } from './lang.pipe';
import { MultiLangPipe } from './multilang.pipe';
import { OrderFinalStatusPipe } from './order-final-status.pipe';
import { OrderStatusI18nPipe } from './order-status-i18n.pipe';
import { OrderStatusPipe } from './order-status.pipe';
import { SortPipe } from './sort.pipe';
import { SearchPipe } from './search.pipe';
import { PortalCurrencyPipe } from './currency/currency.pipe';
import { CurrencySymbolPipe } from './currency-symbol/currency-symbol.pipe';
import { MemoizePipe } from './memoize.pipe';
import { TextCutPipe } from './text-cut.pipe';
import { SafePipe } from './safe.pipe';
import { BooleanPipe } from './boolean.pipe';
import { PortalTemplateCellPipe } from './template-cell.pipe';
import { PortalFullNamePipe } from './full-name/full-name.pipe';
import { PricePerItemPipe } from './price-per-item.pipe';
import { UnitedOrderPipe } from './united-order.pipe';
import { ObjectIsEmptyPipe } from './object-is-empty.pipe';
import { OrderStatusPreTranslatePipe } from './order-status-pretranslate.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';

const pipes = [
  PortalAddressPipe,
  SortPipe,
  SafePipe,
  SearchPipe,
  PortalCurrencyPipe,
  OrderStatusI18nPipe,
  OrderStatusPipe,
  CurrencySymbolPipe,
  MemoizePipe,
  MultiLangPipe,
  TextCutPipe,
  PortalLangPipe,
  OrderFinalStatusPipe,
  BooleanPipe,
  HumanBooleanPipe,
  PortalTemplateCellPipe,
  PortalFullNamePipe,
  PricePerItemPipe,
  UnitedOrderPipe,
  ObjectIsEmptyPipe,
  OrderStatusPreTranslatePipe,
  PhoneNumberPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes,
})
export class PipesModule {}
