import { Pipe, PipeTransform } from '@angular/core';
import _isEmpty from 'lodash/isEmpty';

@Pipe({
  name: 'objectIsEmpty',
  pure: true,
})
export class ObjectIsEmptyPipe implements PipeTransform {
  transform(value: { [x: string]: unknown }): boolean {
    return _isEmpty(value);
  }
}
