import { Injectable } from '@angular/core';
import { BuilderInfoService } from '@portal/builder';
import { CreateSlaThresholdRequest } from '@portal/models/createSlaThresholdRequest';
import { OrderFlowShared } from '@portal/models/orderFlowShared';
import { SellerOrderLimitResponse } from '@portal/models/sellerOrderLimitResponse';
import { SlaThresholdResponse } from '@portal/models/slaThresholdResponse';
import StatusEnum = CreateSlaThresholdRequest.StatusEnum;

export interface CommonStatus<T = unknown> {
  status?: StatusEnum;
  accumulator?: T;
}

export interface TerminalStatus {
  terminal?: boolean;
}

export type CollectCallback = (data: SellerOrderLimitResponse | SlaThresholdResponse | object) => CommonStatus;

export type ReturnCallback = (
  data: CommonStatus[],
  original: SellerOrderLimitResponse | SlaThresholdResponse | OrderFlowShared,
) => (SellerOrderLimitResponse | SlaThresholdResponse | OrderFlowShared) & TerminalStatus;

@Injectable()
export class OrderStatusesService {
  constructor(readonly builderInfoService: BuilderInfoService) {}

  getExtraStatusesIfDefined(status: StatusEnum): StatusEnum[] {
    const statusesMap: Record<StatusEnum, StatusEnum[]> = this.builderInfoService.settings?.statusesMap;
    if (!status) {
      return [status];
    }

    if (statusesMap?.[status]?.length) {
      return [...statusesMap[status], status];
    }

    return [status];
  }

  filterMappedStatuses(statuses: StatusEnum[]): StatusEnum[] {
    const mappedEntries = Object.entries(this.builderInfoService.settings?.statusesMap || {});

    if (!mappedEntries?.length) {
      return statuses;
    }

    return statuses.filter((status: StatusEnum) => !mappedEntries.find(([_, value]) => value.some(v => v === status)));
  }

  getMappedStatuses(
    commonStatuses: CommonStatus[] = [],
    collectCallback: CollectCallback,
    returnCallback: ReturnCallback,
  ): SellerOrderLimitResponse[] {
    const statuses = this.filterMappedStatuses(Object.values(StatusEnum));
    const dataMap = commonStatuses.reduce(
      (acc, c) => ((acc[c.status] = c), acc),
      {} as Record<StatusEnum, CommonStatus>,
    );

    return statuses.map(s => {
      if (commonStatuses.length) {
        const foundData = commonStatuses.find(lim => lim.status === s);
        if (!foundData) {
          return { status: s } as SellerOrderLimitResponse;
        }

        const total = this.getExtraStatusesIfDefined(foundData.status).map(status => collectCallback(dataMap[status]));

        return returnCallback(total, foundData);
      } else {
        return { status: s } as SellerOrderLimitResponse;
      }
    });
  }
}
